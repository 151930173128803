'use client';

/* eslint-disable max-lines */
import { Dialog, Transition } from '@headlessui/react';
import dynamic from 'next/dynamic';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { X } from 'react-feather';
import usePreviewJobCandidate from '@/modules/JobApplications/hooks/usePreviewJobCandidate';
import { CandidateHeaderData, TabChoice } from '@/modules/Jobs/types/candidate';
import { useDarkMode } from 'usehooks-ts';
const CandidateViewHeader = dynamic(() => import('./components/candidate-view-modal/CandidateViewHeader'));
const CandidateInfoContainer = dynamic(() => import('./containers/CandidateInfoContainer'));
const CandidateCheckContainer = dynamic(() => import('./containers/CandidateCheckContainer'));
type CandidateViewModalProps = {
  showModal: boolean;
  onCloseDialog: () => void;
  candidateData: CandidateHeaderData;
  tabChoice: TabChoice;
  // hiringFlow: HiringFlow;
};
export default function CandidateViewModal({
  showModal,
  onCloseDialog,
  candidateData,
  tabChoice // hiringFlow,
}: CandidateViewModalProps) {
  const {
    candidateHeaderData,
    applicationDetails,
    portfolioDetails,
    resumeDetails,
    isLoadingCandidateData,
    candidateInfoPanelDetails,
    candidateNotesPanelDetails,
    candidateChatPanelDetails
  } = usePreviewJobCandidate(candidateData);
  const [latestTabChoice, setLatestTabChoice] = useState(tabChoice);
  const buttonRef = useRef(null);
  const {
    isDarkMode
  } = useDarkMode();
  useEffect(() => {
    setLatestTabChoice(tabChoice);
  }, [tabChoice]);
  return <Transition.Root show={showModal} as={Fragment} data-sentry-element="unknown" data-sentry-component="CandidateViewModal" data-sentry-source-file="CandidateViewModal.tsx">
      <Dialog as="div" className="relative z-10" onClose={onCloseDialog} initialFocus={buttonRef} data-sentry-element="Dialog" data-sentry-source-file="CandidateViewModal.tsx">
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="CandidateViewModal.tsx">
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="scrollbar-primary fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center lg:p-2">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" data-sentry-element="unknown" data-sentry-source-file="CandidateViewModal.tsx">
              <Dialog.Panel className={`relative min-h-full w-full transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-[95%] lg:my-[1.5vh] lg:max-h-[95vh] lg:max-w-[90%] 2xl:max-w-[1500px] ${isDarkMode ? 'dark-mode dark:shadow-md' : ''}`} data-sentry-element="unknown" data-sentry-source-file="CandidateViewModal.tsx">
                <div className="flex h-full w-full flex-col md:flex-row">
                  <div className="flex flex-1 flex-col py-5">
                    {/* close */}
                    <div className="flex w-full justify-end px-5 md:px-8" ref={buttonRef}>
                      <X className="text-gray-600" size={18} onClick={() => onCloseDialog()} data-sentry-element="X" data-sentry-source-file="CandidateViewModal.tsx" />
                    </div>
                    {/* content */}
                    {/* header */}
                    <CandidateViewHeader {...{
                    isLoadingCandidateData,
                    candidateHeaderData,
                    onCloseDialog
                  }} onChangeTabChoice={(tabChoiceKey: keyof TabChoice) => setLatestTabChoice(p => {
                    const newTabChoice: any = {};
                    Object.keys(p).forEach(k => {
                      newTabChoice[k] = false;
                    });
                    newTabChoice[tabChoiceKey] = true;
                    return newTabChoice;
                  })} data-sentry-element="CandidateViewHeader" data-sentry-source-file="CandidateViewModal.tsx" />
                    {/* body */}
                    <div className="scrollbar-primary grid h-full w-full grid-cols-1 overflow-y-auto lg:grid-cols-3">
                      <CandidateInfoContainer appInterviewDetails={applicationDetails} resumeDetails={resumeDetails} portfolioDetails={portfolioDetails} mainTabChoice={latestTabChoice} data-sentry-element="CandidateInfoContainer" data-sentry-source-file="CandidateViewModal.tsx" />
                      <CandidateCheckContainer candidateInfoPanelDetails={candidateInfoPanelDetails} candidateNotesPanelDetails={candidateNotesPanelDetails} candidateChatPanelDetails={candidateChatPanelDetails} data-sentry-element="CandidateCheckContainer" data-sentry-source-file="CandidateViewModal.tsx" />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>;
}