'use client';
/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { apiUrl } from '@/api.config';
import {
  ApplicationInterviewDetails,
  CandidateInfoPanelDetails,
  CandidateNotesPanelDetails,
  CandidateChatPanelDetails,
  Notes,
  PortfolioDetails,
  ResumeDetails,
} from '@/components/Modals/jobs/components/candidate-info/types';
import { dateFormat, getMonthName } from '@/utils/date.helper';
import Notify from '@/utils/Notify';
import { PostAsync } from '@/utils/Post';
import useProcessAsync from '@/hooks/useProcessAsync';
import { CandidateHeaderData } from '@/modules/Jobs/types/candidate';
import { SingleApplicationResJson } from '@/modules/Jobs/types/singleApplicationRes';
import { useAppSelector } from '@/store/hooks';
import { selectApplicationsState, selectAuthState } from '@/store/selectors';
import { useCountriesQuery } from '@/queries';
import useQueryParams from '@/hooks/useQueryParams';

export default function usePreviewJobCandidate(candidateSimpleData: CandidateHeaderData) {
  const { query } = useQueryParams();
  const { user } = useAppSelector(selectAuthState);

  const { countries } = useCountriesQuery();

  const { previewJobId } = useAppSelector(selectApplicationsState);

  const { previewingApplicationId } = useAppSelector(selectApplicationsState);

  const { isLoading: isLoadingCandidateData, processAsync } = useProcessAsync();

  const [candidateHeaderData, setCandidateHeaderData] =
    useState<CandidateHeaderData>(candidateSimpleData);

  const [applicationDetails, setApplicationDetails] = useState<ApplicationInterviewDetails>();

  const [resumeDetails, setResumeDetails] = useState<ResumeDetails>();

  const [portfolioDetails, setPortfolioDetails] = useState<PortfolioDetails>();

  const [candidateInfoPanelDetails, setCandidateInfoPanelDetails] =
    useState<CandidateInfoPanelDetails>();

  const [candidateNotesPanelDetails, setCandidateNotesPanelDetails] =
    useState<CandidateNotesPanelDetails>();

  const [candidateChatPanelDetails, setCandidateChatPanelDetails] =
    useState<CandidateChatPanelDetails>();

  const jobId = query?.jobId || previewJobId;

  useEffect(() => {
    // fetch current candidate with previewingApplicationId
    (async () => {
      if (!(previewingApplicationId && user?.token)) return;

      const resData = await processAsync(async () => {
        return PostAsync({
          method: 'GET',
          url: `${apiUrl}/jobs/${jobId || ''}/applications/${previewingApplicationId || ''}`,
          token: user?.token,
        });
      });

      if (resData && (resData.status === 'success' || resData.meta?.status === 'success')) {
        const appResData = (resData?.data || {}) as SingleApplicationResJson['data'];

        const {
          avatar,
          uid,
          first_name,
          last_name,
          is_active,
          is_verified,
          is_tested,
          work_experience,
          skills,
          technologies,
          education,
          headline,
          summary,
          country_of_residence,
          has_right_to_work,
          match_score,
          city,
          email,
          phone,
          video_resume,
        } = appResData?.included?.talent?.attributes || {};

        const { ratings, tags, notes, answers, portfolio, resume_path } =
          appResData?.included || {};

        const { text, video, screener } = answers || {};

        const { created_at, status, has_assessment } = appResData?.attributes || {};

        const { match_percentage_breakdown, total_score } = match_score || {};

        const candidateLocation =
          countries.find((c) => c.iso_code === country_of_residence)?.name ||
          city ||
          country_of_residence ||
          '';

        setCandidateHeaderData({
          applicationId: appResData?.id,
          avatarUrl: avatar || '',
          candidateId: uid || 'N/A',
          location: candidateLocation || '',
          candidateName: [first_name ?? 'N/A', last_name ?? 'N/A'].join(' ') || 'N/A',
          candidateCertWord: is_tested ? 'Tested' : '' || 'N/A',
          dateApplied: dateFormat(created_at).dateWithWordMonthAndTime || 'N/A',
          isAvailable: is_active || false,
          isVerified: is_verified || false,
          city: city || 'N/A',
          country: country_of_residence || 'N/A',
          phoneNumber: phone || 'N/A',
          email: email || 'N/A',
          status: status || 'N/A',
          hasAssessment: has_assessment || false,
        });

        setApplicationDetails(() => {
          const {
            language,
            technology,
            industry,
            values,
            salary_expectation,
            job_level,
            company_size,
            availability,
          } = (match_percentage_breakdown || {}) as any;

          const videoQuestions = [
            ...(video?.map((v) => ({
              question: v.question,
              videoAnswerUrl: v.video_url,
            })) || []),
          ];

          if (video_resume?.video_url) {
            videoQuestions.push({
              question: 'Video Resume',
              videoAnswerUrl: video_resume.video_url,
            });
          }

          return {
            matchScores: {
              availability: Number(availability?.toFixed(1)) || 0,
              companySize: Number(company_size?.toFixed(1)) || 0,
              expectedPay: Number(salary_expectation?.toFixed(1)) || 0,
              experienceLevel: Number(job_level?.toFixed(1)) || 0,
              languageScore: Number(language?.toFixed(1)) || 0,
              preferredIndustry: Number(industry?.toFixed(1)) || 0,
              technologiesScore: Number(technology?.toFixed(1)) || 0,
              valuesScore: Number(values?.toFixed(1)) || 0,
              salaryExpectation: Number(salary_expectation?.toFixed(1)) || 0,
              total: Number(total_score?.toFixed(1)) || 0,
            },
            videoQuestions,

            introQuestions:
              text?.map((tq) => {
                return {
                  question: tq.question,
                  textAnswer: tq.answer,
                };
              }) || [],

            screenerQuestions:
              screener?.map((sq) => {
                return {
                  question: sq.question,
                  textAnswer: sq.answer,
                };
              }) || [],
          };
        });

        setResumeDetails({
          resumePath: resume_path || null,
          headline: headline || null,
          bio: summary || null,
          workExperiences:
            work_experience?.map((wE) => {
              const startDate = `${getMonthName(wE.start_date?.month) || 'N/A'}, ${
                wE.start_date?.year || 'N/A'
              }`;
              const endDate = `${getMonthName(wE.start_date?.month) || 'N/A'}, ${
                wE.start_date?.year || 'N/A'
              }`;
              return {
                companyName: wE.company || 'N/A',
                title: wE.role || 'N/A',
                projectDescription: wE.description || 'N/A',
                dateRange: `${startDate} - ${endDate}`,
                responsibilities: wE.responsibilities || [],
              };
            }) || [],
          educationalBackground:
            education.map((ed) => {
              const startDate = `${getMonthName(ed.start_date?.month) || 'N/A'}, ${
                ed.start_date?.year || 'N/A'
              }`;
              const endDate = `${getMonthName(ed.end_date?.month) || 'N/A'}, ${
                ed.end_date?.year || 'N/A'
              }`;
              return {
                qualificationLevel: ed.qualification,
                title: ed.course || 'N/A',
                dateRange: `${startDate} - ${endDate}`,
                institution: ed.school_name || 'N/A',
              };
            }) || [],
          skills: skills || [],
          technologies: technologies || [],
        });

        setPortfolioDetails({
          projects:
            portfolio?.map((p) => {
              const { image, role, url, solution, task, title } = p.attributes || {};
              return {
                id: p.id || '',
                imageUrl: image || '',
                role: role || '',
                solution: solution || '',
                task: task || '',
                title: title || '',
                projectLink: url || '',
              };
            }) || [],
        });

        setCandidateInfoPanelDetails({
          isEligibleToWork:
            Boolean(country_of_residence) &&
            Boolean(has_right_to_work) &&
            has_right_to_work.includes(country_of_residence),
          hasRightToWork: has_right_to_work,
          location: country_of_residence || '',
          prevCandidateRating: ratings?.my_rating || 0,
          averageRating: ratings?.average_rating || 0,
          numAllRatings: ratings?.total_times_rated || 0,
          prevCandidateTags: tags || [],
        });

        setCandidateChatPanelDetails({
          context: {
            type: 'candidate-chat',
            applicationId: appResData?.id,
            jobId,
          },
        });

        setCandidateNotesPanelDetails({
          candidatePrevNotes:
            notes?.map((n) => {
              const { content, created_at: noteDate, written_by } = n.attributes || {};
              const { name } = written_by || {};
              const note: Notes[0] = {
                id: n.id || '',
                noteText: content || '',
                author: name || '',
                createdAt: dateFormat(new Date(noteDate)).normalTime || 'N/A',
              };
              return note;
            }) || [],
        });
      } else if (
        resData &&
        (resData.errors || resData.status === 'error' || resData.meta?.status === 'error')
      ) {
        Notify.error('Could not fetch candidate!');
      }
    })();
  }, [previewingApplicationId, processAsync, jobId, user?.token, countries]);

  return {
    candidateHeaderData,
    isLoadingCandidateData,
    applicationDetails,
    resumeDetails,
    portfolioDetails,
    candidateInfoPanelDetails,
    candidateNotesPanelDetails,
    candidateChatPanelDetails,
  };
}
